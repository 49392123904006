<template>
  <!--    审批历史-->
  <div class="approval-history">
    <!--  '无法提交待审批'  -->
    <el-dialog
      :title="$t('message.approval.cannotsubmit')"
      :visible.sync="failedDialog"
      width="30%"
      :append-to-body="true"
    >
      <p>{{ errInfo }}</p>
    </el-dialog>
    <el-dialog
      @close="closeDialog"
      :title="$t('message.approval.cannotsubmit')"
      :visible.sync="errSubmitDialog"
      width="30%"
      :append-to-body="true"
      :before-close="handleCloseErr"
    >
      <div style="padding-bottom: 10px">
        <p>{{ errSubmitInfo }}</p>
        <p v-for="(item, index) in errSubmitList" :key="index">
          <span>{{ index + 1 }}. </span>
          {{ item }}
        </p>
      </div>
    </el-dialog>
    <div class="history-header" v-show="showHeader">
      <div class="history-header-left">
        <svg class="history-header-left-svg" aria-hidden="true">
          <use href="#icon-history-icon"></use>
        </svg>
        <span>
          <!-- 审批历史 -->
          {{ $t("page.approval.history") }}
        </span>
      </div>
      <div class="history-header-right"></div>
    </div>
    <!--      审批历史的内容流程-->
    <div :class="showHeader ? 'fix-bottom' : 'history-content'">
      <el-button v-show="isFailed" class="preview-btn" @click="goToSubmit(1)">
        <!-- 审批流程预览 -->
        {{ $t("label.approval.preview") }}
      </el-button>
      <el-button
        v-show="showLine && isSubmit"
        class="preview-btn"
        @click="openPreView()"
      >
        <!-- 审批流程预览 -->
        {{ $t("label.approval.preview") }}
      </el-button>
      <div v-for="(item, indexBase) in preData.list" :key="indexBase">
        <!--提交待审批状态-->
        <div class="history-detail" v-if="item.approvalStatus === 'Unpending'">
          <div v-show="!showLine">
            <!--  审批历史抬头-->
            <div class="history-detail-header" v-show="item.approvalName">
              <div class="history-detail-header-l">
                <div class="history-detail-name">{{ item.approvalName }}</div>
                <span>|</span>
                <div class="history-detail-status">
                  <!-- 审批状态： -->
                  {{ $t("label.audit.status") }}:
                  <span>{{ status[item.approvalStatus] }}</span>
                </div>
              </div>
            </div>
            <!--    审批历史步骤条-->
            <div class="text-content">
              <div class="time-line">
                <div v-for="(itemB, index) in item.stepList" :key="index">
                  <div
                    v-for="(itemChlid, indexChild) in itemB.actorList"
                    :key="indexChild"
                    class="time-line-div"
                  >
                    <div class="time-line-div-l">
                      <div class="left-date">{{ itemChlid.actorTime }}</div>
                      <div class="middle-icon" ref="circular">
                        <svg class="status-icon grey" aria-hidden="true">
                          <use href="#icon-dengdai"></use>
                        </svg>
                      </div>
                    </div>
                    <div class="right-box-undone">
                      <div class="step-title">{{ itemB.stepTitle }}</div>
                      <div class="step-result">
                        {{ itemChlid.assignName
                        }}{{
                          itemChlid.stepStatus == null
                            ? ""
                            : "(" + status[itemChlid.stepStatus] + ")"
                        }}
                      </div>
                      <el-tooltip popper-class="my-tooltip" placement="bottom">
                        <div class="step-remark-tip" slot="content">
                          {{ itemChlid.comments }}
                        </div>
                        <div class="step-remark">
                          {{ itemChlid.comments }}
                        </div>
                      </el-tooltip>
                    </div>
                    <!--                <div class="line-position solid-line" v-show="item.key!=1&&item.done"></div>-->
                    <div
                      class="line-position dashed-line"
                      v-show="itemB.stepId != 'Started'"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--已提交状态-->
        <div class="history-detail" v-if="item.approvalStatus !== 'Unpending'">
          <!--  审批历史抬头-->
          <div class="history-detail-header">
            <div class="history-detail-header-l">
              <div class="history-detail-name">{{ item.approvalName }}</div>
              <span>|</span>
              <div class="history-detail-status">
                <!-- 审批状态： -->
                {{ $t("label.audit.status") }}:
                <span
                  :class="[
                    { reject: item.approvalStatus == 'Rejected' },
                    { pass: item.approvalStatus == 'Approved' },
                    { dengdai: item.approvalStatus == 'Pending' },
                  ]"
                  >{{ status[item.approvalStatus] }}</span
                >
              </div>
            </div>
            <div class="history-detail-header-r">
              <svg
                :class="[
                  { 'img-rotate': item.openStatus },
                  'history-detail-header-r-svg',
                ]"
                @click="rotateImg(item, indexBase)"
                aria-hidden="true"
              >
                <use href="#icon-dropDown"></use>
              </svg>
            </div>
          </div>
          <!--    审批历史步骤条-->
          <div class="text-content" v-show="item.openStatus">
            <div
              class="time-line"
              v-for="(item, index) in item.stepList"
              :key="index"
            >
              <div
                v-for="(itemChlid, indexChild) in item.actorList"
                :key="indexChild"
                class="time-line-div"
                v-show="itemChlid.status !== 'NoResponse'"
              >
                <div class="time-line-div-l">
                  <div class="left-date">
                    {{
                      itemChlid.status !== "Pending" &&
                      itemChlid.stepStatus !== "Pending"
                        ? itemChlid.actorTime
                        : ""
                    }}
                  </div>
                  <div class="middle-icon" ref="circular">
                    <svg
                      class="status-icon"
                      v-show="
                        itemChlid.status === 'Started' ||
                        itemChlid.stepStatus === 'Started'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-tijiao"></use>
                    </svg>
                    <svg
                      class="status-icon"
                      v-show="
                        itemChlid.status === 'Recalled' ||
                        itemChlid.stepStatus === 'Recalled'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-diaohui"></use>
                    </svg>
                    <svg
                      class="status-icon grey"
                      v-show="
                        itemChlid.status === 'NoResponse' ||
                        itemChlid.stepStatus === 'NoResponse'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-dengdai"></use>
                    </svg>
                    <svg
                      class="status-icon grey"
                      v-show="
                        itemChlid.status === 'NoStart' ||
                        itemChlid.stepStatus === 'NoStart'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-dengdai"></use>
                    </svg>
                    <svg
                      class="status-icon"
                      v-show="
                        itemChlid.status === 'Pending' ||
                        itemChlid.stepStatus === 'Pending'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-dengdai"></use>
                    </svg>
                    <svg
                      class="status-icon"
                      v-show="
                        itemChlid.status === 'InsertApproval' ||
                        itemChlid.stepStatus === 'InsertApproval'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-jiaqian"></use>
                    </svg>
                    <svg
                      class="status-icon"
                      v-show="
                        itemChlid.status === 'Rejected' ||
                        itemChlid.stepStatus === 'Rejected'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-jujue"></use>
                    </svg>
                    <svg
                      class="status-icon"
                      v-show="
                        itemChlid.status === 'Approved' ||
                        itemChlid.stepStatus === 'Approved'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-yishenpi"></use>
                    </svg>
                    <svg
                      class="status-icon"
                      v-show="
                        itemChlid.status === 'ReAssign' ||
                        itemChlid.stepStatus === 'ReAssign'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-chongxinfenpei"></use>
                    </svg>
                    <svg
                      class="status-icon grey"
                      v-show="
                        itemChlid.status === 7 || itemChlid.stepStatus === '7'
                      "
                      aria-hidden="true"
                    >
                      <use href="#icon-jieshu"></use>
                    </svg>
                  </div>
                </div>
                <div
                  :class="item.status === 7 ? 'right-box-undone' : 'right-box'"
                >
                  <div class="step-title">{{ item.stepTitle }}</div>
                  <div
                    class="step-result"
                    v-if="
                      itemChlid.actorName !== itemChlid.assignName &&
                      itemChlid.actorName !== undefined
                    "
                  >
                    <span>
                      {{ $t("vue_label_approve_actual_operator") }}:
                      {{ itemChlid.actorName }}
                      {{
                        itemChlid.stepStatus == null
                          ? "(" + status[itemChlid.status] + ")"
                          : "(" + status[itemChlid.stepStatus] + ")"
                      }}
                    </span>
                    <span style="margin-right: 100px"></span>
                    <span>
                      <!-- 被分配人: -->
                      {{ $t("label.assigned") }}
                      {{ itemChlid.assignName }}
                    </span>
                  </div>
                  <div class="step-result" v-else>
                    {{ itemChlid.assignName }}
                    {{
                      itemChlid.stepStatus == null
                        ? "(" + status[itemChlid.status] + ")"
                        : "(" + status[itemChlid.stepStatus] + ")"
                    }}
                  </div>
                  <el-popover
                    placement="bottom-start"
                    width="400"
                    trigger="hover"
                    :content="itemChlid.comments"
                  >
                    <div class="step-remark" slot="reference">
                      {{ itemChlid.comments }}
                    </div>
                  </el-popover>
                </div>
                <div
                  class="line-position solid-line"
                  v-show="
                    item.stepStatus != 'Started' &&
                    (itemChlid.status == 'Pending' ||
                      itemChlid.status == 'Started' ||
                      itemChlid.status == 'Approved' ||
                      itemChlid.status == 'Recalled' ||
                      itemChlid.status == 'Rejected' ||
                      itemChlid.status == 'ReAssign' ||
                      itemChlid.status == 'InsertApproval' ||
                      itemChlid.stepStatus == 'InsertApproval' ||
                      itemChlid.stepStatus == 'Pending' ||
                      itemChlid.stepStatus == 'Started' ||
                      itemChlid.stepStatus == 'Approved' ||
                      itemChlid.stepStatus == 'Recalled' ||
                      itemChlid.stepStatus == 'Rejected' ||
                      itemChlid.stepStatus == 'ReAssign')
                  "
                ></div>
                <div
                  class="line-position dashed-line"
                  v-show="
                    item.stepStatus != 'Started' && item.stepStatus == 'NoStart'
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      提交待审核下的收起操作-->
      <div v-show="!showLine && isSubmit" class="close-btn-box">
        <span class="close-btn" @click="closePreView()">{{
          $t("vue_label_approve_put_away")
        }}</span>
      </div>
    </div>
    <!-- 下一个审批人组件 -->
    <div>
      <nextApproval
        ref="nextApprovalref"
        @approvalSuccess="approvalSuccess"
      ></nextApproval>
    </div>
  </div>
</template>

<script>
/**
 * @description 审批历史组件
 * 功能
 * 1. 展示审批流程
 * 2. 处理审批操作
 */
import { getApprovalPre, submitApproval } from "./api.js";
import detailSendCard from "@/utils/robotMessage.js";
import nextApproval from "./approval-dialog";

export default {
  name: "approval-history",
  props: {
    // 记录id
    historyID: { type: String },
    // 审批流程名称
    itemTitle: [String, Array],
    // 是否展示审批
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    nextApproval,
  },

  data() {
    return {
      // 是否自动进入下一步
      isAuto: false,
      // 错误提示
      errInfo: "",
      // 错误提示
      errSubmitInfo: "",
      // 提交时具体错误信息
      errSubmitList: [],
      // 提交时错误弹窗
      errSubmitDialog: false,
      // 是否失败
      isFailed: false,
      // 失败弹窗
      failedDialog: false,
      // 时间轴数据
      preData: {},
      // 状态枚举
      status: {
        // 已提交
        Started: this.$i18n.t("label.submitted"),
        // 未响应
        NoResponse: this.$i18n.t("vue_label_approve_no_response"),
        // 已批准
        Approved: this.$i18n.t("label.approved"),
        // 待处理
        Pending: this.$i18n.t("label.pending"),
        // 加签
        InsertApproval: this.$i18n.t("label.insert.approval"),
        // 待提交
        Unpending: this.$i18n.t("vue_label_approve_to_submit"),
        // 已调回
        Recalled: this.$i18n.t("lebel.alreadyback"),
        // 已拒绝
        Rejected: this.$i18n.t("label.refused"),
        // 未开始
        NoStart: this.$i18n.t("label.nostart"),
        // 已重新分配
        ReAssign: this.$i18n.t("label.redistributioned"),
      },
      // 预览时是否展开时间线
      showLine: true,
      // 是否提交待审核
      isSubmit: false,
      //详情页群策的群ID
      detailGroupId: "",
      //详情页客户或业务机会记录ID
      recordId: "",
    };
  },
  methods: {
    /**
     * @description 关闭弹窗时恢复审批按钮状态
     */
    closeDialog() {
      this.$emit("closeLoading");
    },
    //关闭详情页loading
    /**
     * @description 关闭弹窗时恢复审批按钮状态
     */
    closeLoading() {
      this.$emit("closeLoading");
    },
    /**
     * @description 提交待审批成功刷新页面
     */
    approvalSuccess() {
      this.$router.go(0);
    },
    /**
     * @description 跳转重新分配页面
     */
    
    deliver() {
      this.$router.push({
        path: "/approvalCenter/distributionAagain",
        query: {
          value: this.preData.workitemid,
          detailGroupId: this.detailGroupId,
          recordId: this.recordId,
        },
      });
    },
    /**
     * @description 提交待审核
     * @param {flag} 错误标识
     */
    goToSubmit(flag) {
      if (flag && flag === 1) {
        this.failedDialog = true;
      } else {
        submitApproval({ relatedId: this.historyID })
          .then((res) => {
            this.closeLoading();
            if (res.result) {
              this.$message({
                showClose: true,
                type: "success",
                // '提交成功'
                message: this.$i18n.t("label.webtocloudcc.submit.success"),
              });
              if (this.detailGroupId != "") {
                detailSendCard(this.detailGroupId, {
                  approval_value: `${localStorage.getItem(
                    "username"
                  )}${this.$i18n.t("label.submitforapproval")}`,
                  title: this.$i18n.t("vue_label_chat_approval_message"),
                  recordId: this.recordId,
                  type: "APPROVAL_CARD",
                  // body: [
                  //   {
                  //     fieldLable: "日期",
                  //     fieldValue: transformTime(new Date().getTime())
                  //   }
                  // ]
                });
              }
              this.detailGroupId = "";
              this.recordId = "";
              // this.getApprovalPreview();
              this.$Bus.$emit("approvalrefresh");
              this.isSubmit = false;
            } else {
              if (res.returnCode == "Manual") {
                this.$refs.nextApprovalref.IsShowdialog(
                  true,
                  this.historyID,
                  this.detailGroupId,
                  this.recordId
                );
              } else if (res.returnCode !== "Manual") {
                this.errSubmitInfo = res.returnInfo;
                this.errSubmitList = res.data || [];
                this.errSubmitDialog = true;
                // this.$message.warning(this.errInfo)
              }
            }
            this.$Bus.$emit("recovery-submit-status");
          })
          .catch((err) => {
            if (err.returnCode == "Manual") {
              this.$refs.nextApprovalref.IsShowdialog(
                true,
                this.historyID,
                this.detailGroupId,
                this.recordId
              );
            } else if (err.returnCode !== "Manual") {
              this.errSubmitInfo = err.returnInfo;
              this.errSubmitList = err.data || [];
              this.errSubmitDialog = true;
              // this.$message.warning(this.errInfo)
            }
          });
      }
    },
    /**
     * @description 外部请求方法
     * @param {flag} 操作标识
     * @param {groupId} 群策id
     * @param {recordId} 记录id
     */
    
    parentClickHistory(flag, detailGroupId, recordId) {
      // 0提交待审批、1调回审批、2进行审批、3重新分配
      this.detailGroupId = detailGroupId;
      this.recordId = recordId;
      if (flag == 1) {
        this.goRecall();
      } else if (flag == 0) {
        this.goToSubmit();
      } else if (flag == 2) {
        // 进行审批
        let config = {
          objid: this.historyID,
          workItemid: this.preData.workitemid,
          objname: this.itemTitle,
          detailGroupId: this.detailGroupId,
          recordId: this.recordId,
        };
        this.$router.push({
          path: "/approvalCenter/approvalRequest",
          query: {
            value: JSON.stringify(config),
            isHistory: "2",
          },
        });
        this.$Bus.$emit("approval-show-or-hidden", true);
      } else if (flag == 3) {
        // 重新分配
        this.deliver();
      }
    },
    /**
     * @description 调回审批
     */
    
    goRecall() {
      let option = {
        id: this.historyID,
        workItemid: this.preData.workitemid,
        title: this.itemTitle,
        detailGroupId: this.detailGroupId,
        recordId: this.recordId,
      };
      this.$router.push({
        path: "/approvalCenter/approvalRecall",
        query: { value: option },
      });
    },
    /**
     * @description 无法审批时打开弹窗
     */
    
    goSubmit() {
      this.failedDialog = true;
    },
    /**
     * @description 旋转展开关闭某个审批流程时的小三角
     */
    rotateImg(item, index) {
      this.preData.list[index].openStatus =
        !this.preData.list[index].openStatus;
      this.$set(this.preData.list, index, this.preData.list[index]);
    },
    /**
     * @description 打开审批流程
     */
    openPreView() {
      this.showLine = false;
    },
    /**
     * @description 关闭审批流程
     */
    closePreView() {
      this.showLine = true;
    },
    /**
     * @description 对象数组去重
     * @param {arr} 对象数组
     */
    uniqObjInArray(objarray) {
      let obj = {};
      return objarray.reduce((cur, next) => {
        obj[next.status] ? "" : (obj[next.status] = true && cur.push(next));
        return cur;
      }, []); //设置cur默认类型为数组，并且初始值为空的数组
    },
    /**
     * @description 关闭弹窗时恢复审批按钮状态
     */
    handleCloseErr() {
      this.$Bus.$emit("recovery-submit-status");
      this.errSubmitDialog = false;
    },
    /**
     * @description 获取审批预览
     */
    getApprovalPreview() {
      let option = {
        relatedId: this.historyID,
      };
      getApprovalPre(option)
        .then((res) => {
          if (res.result === false) {
            this.errInfo = res.returnInfo;
            this.isFailed = true;
            let op = {
              showSubmit: true,
            };
            setTimeout(() => {
              this.$emit("showWhichBtn", op);
            }, 700);
          } else {
            this.preData = res.data || {};
            if (
              this.preData.list.length > 0 &&
              this.preData.list[0].approvalStatus === "Unpending"
            ) {
              this.isSubmit = true;
            }
            this.preData.list.map((item, index) => {
              if (index === 0) {
                item.openStatus = true;
              } else {
                item.openStatus = false;
              }
              if (item.approvalStatus === "Recalled") {
                //
                item.stepList.map((itemChild, ) => {
                  if (itemChild.stepStatus === "Recalled") {
                    // ;
                    itemChild.actorList = this.uniqObjInArray(
                      itemChild.actorList
                    );
                  }
                });
              }
              if (item.approvalStatus === "Pending") {
                // 排序
                item.stepList.map((itemChild, ) => {
                  if (itemChild.stepStatus === "Pending") {
                    itemChild.actorList.sort(function (a, b) {
                      return a.actorTime < b.actorTime ? 1 : -1;
                    });
                    itemChild.actorList.map((itemC, indexC) => {
                      if (itemC.status === "Pending") {
                        let tempObj = itemC;
                        itemChild.actorList.splice(indexC, 1);
                        itemChild.actorList.unshift(tempObj);
                      }
                    });
                  }
                });
              }
            });
            let op = {
              showSubmit: this.preData.isCanSubmit,
              showRecall: this.preData.isRecall,
              showReassign: this.preData.isReassign,
              showApproval: this.preData.isApproval,
            };
            if (
              this.preData.list.length === 1 &&
              this.preData.list[0].approvalStatus === "Unpending"
            ) {
              this.isAuto = true;
            }
            if (this.isAuto) {
              op.isAuto = true;
            }
            setTimeout(() => {
              this.$emit("showWhichBtn", op);
            }, 700);
          }
        })
        .catch((err) => {
          if (err && err.result && err.result === false) {
            this.errInfo = err.returnInfo;
            this.isFailed = true;
            let op = {
              showSubmit: true,
            };
            setTimeout(() => {
              this.$emit("showWhichBtn", op);
            }, 700);
          }
        });
    },
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
  },
  created() {
    this.getApprovalPreview();
  },
  activated() {
    this.getApprovalPreview();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1350px) {
  .right-box {
    .step-remark {
      width: 600px;
    }
  }
}
@media screen and (min-width: 1351px) and (max-width: 1600px) {
  .right-box {
    .step-remark {
      //width: 800px;
    }
  }
}
.approval-history {
  border-radius: 3px;
  border: 1px solid #dddbda;
  width: 100%;
  background-color: #fff;
  //margin-top: 10px;
}
::v-deep .el-tooltip__popper {
  background-color: #ffffff !important;
}

/*头部*/
.history-header {
  width: 100%;
  height: 38px;
  background: #f3f2f2;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
  .history-header-left {
    display: flex;
    align-items: center;
    .history-header-left-svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    > img {
      width: 20px;
      height: 20px;
      //border-radius: 3px;
      //border: 1px solid #dddbda;
      margin-right: 10px;
    }
    > span {
      //width: 56px;
      font-size: 14px;
      font-weight: 400;
      color: #080707;
    }
  }
  .history-header-right {
    > .history-header-btn {
      width: 82px;
      height: 28px;
      background: #ffffff;
      border-radius: 3px;
      border: 1px solid #c8c6c6;
      font-size: 12px;
      font-weight: 400;
      color: #080707;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/*内容*/
.fix-bottom {
  padding: 20px 20px 100px 20px;
  box-sizing: border-box;
}
.history-content {
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  .preview-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin: 0;
    background: #f3f3f3 !important;
    border: 1px solid #dddbda;
    font-size: 12px;
    font-weight: 500;
    color: #006dcc !important;
    line-height: 24px;
  }
  .close-btn-box {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .close-btn {
      width: 28px;
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #006dcc;
      line-height: 21px;
      cursor: pointer;
    }
  }
}
.history-detail {
  .history-detail-header {
    //max-width: 880px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .history-detail-header-l {
      display: flex;
      align-items: center;
      > span {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
      }
      .history-detail-name {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        margin-right: 15px;
      }
      .history-detail-status {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        margin-left: 15px;
      }

      .history-detail-status .pass {
        color: #2aac4f;
      }
      .history-detail-status .reject {
        color: red;
      }
      .history-detail-status .dengdai {
        color: #ed9b15;
      }
    }
    .history-detail-header-r {
      > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: all 0.5s;
      }
      .history-detail-header-r-svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: all 0.5s;
      }
      .img-rotate {
        transform: rotate(-180deg);
        transition: all 0.5s;
      }
    }
  }
}

.solid-line {
  border-left: 1px solid #979797;
}

.dashed-line {
  border-left: 1px dashed #979797;
}

.line-position {
  position: absolute;
  height: 80px;
  left: 136px;
  top: 45px;
}

.grey {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

.time-line {
  position: relative;
  width: 100%;
}
.time-line-div-l {
  //width: 15%;
  display: flex;
  align-items: center;
}
.time-line-div {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 65px;
  margin-bottom: 16px;
  .left-date {
    //height: 20px;
    width: 120px;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-right: 6px;
  }
  .middle-icon {
    width: 21px;
    height: 21px;
    z-index: 10;
    margin-right: 25px;
  }
  .right-box {
    padding: 9px 14px;
    //background: #f3f3f3;
    border: 1px solid #f2f2f3;
    border-radius: 3px;
    color: #333333;
    width: 85%;
    .step-title {
      font-size: 12px;
      font-weight: 600;
      color: #333333;
      line-height: 18px;
    }
    .step-result {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }
    .step-remark {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
  .right-box-undone {
    padding: 9px 14px;
    //background: #f3f3f3;
    border: 1px solid #f2f2f3;
    border-radius: 3px;
    color: #333333;
    width: 85%;
    .step-title {
      font-size: 12px;
      font-weight: 600;
      color: #999999;
      line-height: 18px;
    }
    .step-result {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }
    .step-remark {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}
.status-icon {
  width: 21px;
  height: 21px;
}
.step-remark-tip {
  line-height: 18px;
  color: #ffffff;
}
</style>
